import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import 'twin.macro'

import SEO from '../components/seo'

const HomeEntry = ({ data }) => {
  const { entry } = data

  return (
    <>
      <SEO data={entry.seomatic} />
    </>
  )
}

export default HomeEntry

HomeEntry.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query($id: ID) {
    entry: craftHomeCfHomeCfEntry(remoteId: { eq: $id }) {
      seomatic {
        ...SEO
      }
    }
  }
`
